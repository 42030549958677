import {Tenant} from "../services/tenant.service";
import {TenantConfig} from "./TenantConfig";
import {NavigationItem} from "../../shared/models/NavigationItem";

export class TenantConfigList {

  static tenantConfigs: {
    [key: string]: {
      environments: {
        [key: string]: TenantConfig
      }
    }
  } = {};

  static defaultTenantConfig = TenantConfig.create({
    tenant: Tenant.TALENTVIEW,
    siteName: 'Talentview',
    baseUrl: '/',
    logo: '/assets/images/logos/tv_blue_logo.svg',
    internalLogoLink: '/dashboard',
    notification: {
      description: "A summary of our top rated articles, the latest news and information about what’s going on here at Talentview"
    },
    recaptcha: {
      sitekey: ""
    },
    signIn: {
      layout: 'cover',
      fullscreen: true,
      title: "Log in to Talentview",
      logo_visible: true,
    },
    locker: {
      enabled: true,
    },
    dashboard: {
      header: {
        enabled: true
      }
    },
    signUp: {
      enabled: true,
      layout: 'cover',
      fullscreen: true,
      title: "Kickstart your career",
      subtitle: "Talentview helps you find your dream job, and get the skills you need to land it.",
      completionPage: {
        enabled: true,
        url: '/account/student-introduction'
      },
      logo_visible: true,
      verificationEmailReceived: {
        title: "You’ve got mail",
        subtitle: "",
        hideResend: false
      }
    },
    organisationHub: {
      AZMenuItems: 'sponsor_partner,company,supporting_organisation,university,fe_college',
      organisationListType: 'list',
      organisationPageLayout: 'wide',
      navigation: {
        showJobsTab: true
      }
    },
    profile: {
      profilePageDisabled: true,
      avatarDisabled: false,
      infoText: "We’ll use the information you enter here to show you more relevant jobs.",
      headerSubtitle: "Your profile is your window to the world. Use it to show others your skills, experiences and achievements.",
      navigation: {
        config: {
          homeTab: 'introduction'
        },
        items: [
          NavigationItem.create({
            routerLink: '/dashboard/profile',
            title: 'Introduction'
          }),
          NavigationItem.create({
            routerLink: '/dashboard/profile/education',
            title: 'Education & Qualifications'
          }),
          NavigationItem.create({
            routerLink: '/dashboard/profile/skills',
            title: 'Key Skills'
          }),
          NavigationItem.create({
            routerLink: '/dashboard/profile/work-history',
            title: 'Work History'
          }),
          NavigationItem.create({
            routerLink: '/dashboard/profile/cv',
            title: 'CV'
          }),
          NavigationItem.create({
            routerLink: '/dashboard/profile/portfolio',
            title: 'Portfolio'
          })
        ]
      }
    },
    topNavigation: {
      config: {},
      hideNotifications: false,
      items: [
        NavigationItem.create({
          routerLink: '/account/sign-in',
          title: 'Log In',
          visibleIfGuest: true
        }),
        NavigationItem.create({
          routerLink: '/account/sign-up',
          title: 'Sign Up',
          visibleIfGuest: true
        }),
        NavigationItem.create({
          routerLink: '/dashboard',
          title: 'My Dashboard',
          visibleIfMember: true
        })
      ]
    },
    internalNavigation: {
      admin: {
        config: {},
        itemGroups: [
          {
            group: 'default',
            title: null,
            items: [
              NavigationItem.create({
                routerLink: '/dashboard',
                title: 'Dashboard',
                icon: 'faHeart'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/account',
                title: 'Account Settings',
                icon: 'faHeart'
              })
            ]
          }
        ]
      },
      company: {
        config: {},
        itemGroups: [
          {
            group: 'default',
            title: null,
            items: [
              NavigationItem.create({
                routerLink: '/dashboard',
                title: 'Dashboard',
                icon: 'faGauge'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/candidate-search',
                title: 'Candidates',
                icon: 'faBinoculars'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/interest-alerts',
                title: 'Contacted Candidates',
                icon: 'faBells'
              }),
              /*NavigationItem.create({
                routerLink: '/dashboard/interest-alerts/archive',
                title: 'Archived Candidates',
                icon: 'faBells'
              }),*/
              NavigationItem.create({
                routerLink: '/dashboard/favourites',
                title: 'Favourites',
                icon: 'faHeart'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/saved-searches',
                title: 'Saved Searches',
                icon: 'faMagnifyingGlass'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/live-applications',
                title: 'Live Opportunities',
                icon: 'faListCheck'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/expired-applications',
                title: 'Expired Opportunities',
                icon: 'faListCheck'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/archived-applications',
                title: 'Archived Opportunities',
                icon: 'faListCheck'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/reports',
                title: 'Reports',
                icon: 'faChartSimple'
              })

            ]
          }/*,
          {
            group: 'manage',
            title: 'Manage',
            items: [
              NavigationItem.create({
                routerLink: '/dashboard/vacancies',
                title: 'Vacancies',
                icon: 'faHelmetSafety'
              }),
            ]
          }*/
        ]
      },
      individual: {
        config: {},
        itemGroups: [
          {
            group: 'default',
            title: null,
            items: [
              NavigationItem.create({
                routerLink: '/dashboard',
                title: 'Dashboard',
                icon: 'faGauge'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/profile/job-search-preferences',
                title: 'Search Preferences / Profile',
                icon: 'faIdBadge'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/favourites',
                title: 'Favourites',
                icon: 'faHeart'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/saved-searches',
                title: 'Saved Searches',
                icon: 'faMagnifyingGlass'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/organisation-subscriptions',
                title: 'Followed Companies',
                icon: 'faBuildingMemo'
              }),
              NavigationItem.create({
                routerLink: '/dashboard/interest-alerts',
                title: 'Interest from Companies',
                icon: 'faBells',
                showNotificationBadge: true
              }),
              NavigationItem.create({
                routerLink: '/dashboard/applications',
                title: 'Application Activities',
                icon: 'faListCheck'
              }),


            ]
          }
        ]
      }
    }
  });

  static get() {
    // Default tenant (Talentview)
    this.tenantConfigs[Tenant.TALENTVIEW] = {
      environments: {
        production: {
          ...JSON.parse(JSON.stringify(this.defaultTenantConfig)), ...{
            signUp: {
              enabled: false
            }
          }
        } as TenantConfig
      }
    }

    // Construction tenant
    this.tenantConfigs[Tenant.CONSTRUCTION] = {
      environments: {
        production: TenantConfig.create({
          tenant: Tenant.CONSTRUCTION,
          logo: '/assets/tenants/construction/construction-logo.svg',
          organisationHub: {
            AZMenuItems: 'construction_sector_company,construction_supporting_organisation,university,fe_college'
          }
        })
      }
    };

    // Deep copy of default tenant config as DNCP tenant
    this.tenantConfigs[Tenant.DNCP] = {
      environments: {
        production: {
          ...JSON.parse(JSON.stringify(this.defaultTenantConfig)), ...{
            tenant: Tenant.DNCP,
            siteName: 'Destination Nuclear Careers Portal',
            hosts: [
              'dev.destinationnuclearcareersportal.co.uk',
              'dev.dncp.test'
            ],
            baseUrl: '/',
            apiBaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
            apiV1BaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
            logo: '/assets/tenants/destination-nuclear/destination_nuclear_logo_new.png',
            internalLogoLink: '/',
            notification: {
              description: "I agree to be contacted by Destination Nuclear Careers Portal and partner organisations about careers and related opportunities in the nuclear sector."
            },
            recaptcha: {
              sitekey: "6LdBoA4qAAAAAIdF8xzVbTjfK6x_CfqbALEtYp-X"
            },
            locker: {
              enabled: false,
            },
            dashboard: {
              header: {
                enabled: false
              }
            },
            signIn: {
              layout: 'centered',
              fullscreen: false,
              title: "Destination Nuclear Careers Portal Login",
              logo_visible: false,
            },
            signUp: {
              enabled: true,
              layout: 'centered',
              fullscreen: false,
              title: "Candidate Registration",
              subtitle: "",
              completionPage: {
                enabled: true,
                url: '/account/individual-introduction'
              },
              logo_visible: false,
              verificationEmailReceived: {
                title: "Thank you for signing-up.",
                subtitle: "Thank you for signing-up.",
                hideResend: true
              }
            },
            organisationHub: {
              organisationListType: 'grid',
              AZMenuItems: 'sponsor_partner,company,supporting_organisation,university,fe_college',
              organisationPageLayout: 'narrow',
              navigation: {
                showJobsTab: false
              }
            },
            profile: {
              profilePageDisabled: true,
              avatarDisabled: true,
              infoText: "",
              headerSubtitle: "Increase your chances of finding a job by letting Employers see your profile and CV. To do this, fill in the information below, save and publish.",
              fields: {
                config: {},
                items: [
                  {key: 'areas_of_interest', navigation: 'introduction'},
                  {key: 'preferred_job_type', navigation: 'introduction'},
                  {key: 'preferred_type_of_work', navigation: 'introduction'},
                  {key: 'preferred_work_location', navigation: 'introduction'},
                  {key: 'qualifications', navigation: 'introduction'},
                  {key: 'cv', navigation: 'introduction'}
                ]
              },
              navigation: {
                config: {
                  homeTab: 'job-search-preferences'
                },
                items: [
                  NavigationItem.create({
                    routerLink: '/dashboard/profile/job-search-preferences',
                    title: 'Job Search Preferences',
                    id: 'job-search-preferences'
                  }),
                  NavigationItem.create({
                    routerLink: '/dashboard/profile/profile-and-cv',
                    title: 'Profile and CV',
                    id: 'profile-and-cv'
                  })
                ]
              }
            },
          }
        } as TenantConfig
      }
    };

    this.tenantConfigs[Tenant.DNCP].environments['production'].topNavigation.items = [
      /*NavigationItem.create({
        routerLink: '/',
        title: 'Home',
        hiddenExternally: true,
        routerLinkActiveOptions: {exact: true}
      }),*/
      NavigationItem.create({
        routerLink: '/jobs',
        title: 'Browse Opportunities'
      }),
      NavigationItem.create({
        routerLink: '/what-we-offer',
        title: 'What We Offer'
      }),
      NavigationItem.create({
        routerLink: '/organisation-hub',
        title: 'Organisations Hub'
      }),
      NavigationItem.create({
        routerLink: '/news',
        title: 'News'
      }),
      NavigationItem.create({
        routerLink: '/account/sign-in',
        title: 'Log In',
        visibleIfGuest: true
      }),
      NavigationItem.create({
        routerLink: '/account/sign-up/individual',
        title: 'Sign Up',
        visibleIfGuest: true
      }),
      NavigationItem.create({
        routerLink: '/dashboard',
        title: 'Dashboard',
        visibleIfMember: true,
        class: 'dashboard-link'
      }),
      /*NavigationItem.create({
        routerLink: '/sign-out',
        icon: 'faArrowRightFromBracket',
        title: 'Sign out',
        visibleIfMember: true,
        tooltip: 'Sign out',
        hiddenInternally: true,
        callbackFn: "logout"
      })*/
    ]

    this.tenantConfigs[Tenant.DNCP].environments['production'].topNavigation.hideNotification = true;

    // DNCP phase2 tenant environment config
    this.tenantConfigs[Tenant.DNCP].environments['phase2'] = {
      ...JSON.parse(JSON.stringify(this.tenantConfigs[Tenant.DNCP].environments['production'])), ...{
        //apiBaseUrl: 'https://api.phase2.destinationnuclearcareersportal.co.uk/api/v1/',
        //apiV1BaseUrl: 'https://api.phase2.destinationnuclearcareersportal.co.uk/api/v1/',
        apiBaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
        apiV1BaseUrl: 'https://api.devel.destinationnuclearcareersportal.co.uk/api/v1/',
        hosts: [
          'phase2.destinationnuclearcareersportal.co.uk',
          'phase2.dncp.test'
        ],
      }
    }

    return this.tenantConfigs;
  }
}
