import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {IconPipe} from "../../pipes/icon.pipe";
import {DateTimeFormatPipe} from "../../pipes/date-time-format.pipe";
import {RouteTenantPipe} from "../../pipes/route-tenant.pipe";
import {SecureImagePipe} from "../../pipes/secure-image.pipe";
import {SafePipe} from "../../pipes/safe.pipe";
import {TenantUtilsModule} from "../../../tenant/tenant-utils/tenant-utils.module";
import {TimeAgoPipe} from "../../pipes/time-ago.pipe";
import {FilesizePipe} from "../../pipes/filesize.pipe";
import {SortByPipe} from "../../pipes/sort-by.pipe";
import {FilterByPipe} from "../../pipes/filter-by.pipe";
import {LoadingAnimationComponent} from "../../components/loading-animation/loading-animation.component";
import {ColorizePipe} from "../../pipes/colorize.pipe";
import {HtmlSecureImagePipe} from "../../pipes/html-secure-image.pipe";
import {EmbedVideoPipe} from "../../pipes/embed-video.pipe";
import {YesNoPipe} from "../../pipes/YesNoPipe";
import {PercentagePipe} from "../../pipes/PercentagePipe";
import {LimitPipe} from "../../pipes/limit.pipe";

@NgModule({
  declarations: [
    IconPipe,
    DateTimeFormatPipe,
    FilesizePipe,
    SecureImagePipe,
    SafePipe,
    TimeAgoPipe,
    SortByPipe,
    FilterByPipe,
    ColorizePipe,
    YesNoPipe,
    HtmlSecureImagePipe,
    LoadingAnimationComponent,
    EmbedVideoPipe,
    PercentagePipe,
    LimitPipe
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TenantUtilsModule
  ],
  exports: [
    CommonModule,
    IconPipe,
    RouteTenantPipe,
    TenantUtilsModule,
    FilesizePipe,
    SecureImagePipe,
    FontAwesomeModule,
    DateTimeFormatPipe,
    SafePipe,
    TimeAgoPipe,
    SortByPipe,
    FilterByPipe,
    LoadingAnimationComponent,
    ColorizePipe,
    HtmlSecureImagePipe,
    EmbedVideoPipe,
    YesNoPipe,
    PercentagePipe,
    LimitPipe
  ]
})
export class GraphicModule { }
