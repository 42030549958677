import {JsonAdapter} from "../helpers/JsonAdapter";
import {User} from "./User";
import {Tenant, TenantService} from "../../tenant/services/tenant.service";
import {Organisation} from "./Organisation";
import {environment} from "../../../environments/environment";
import {EstablishmentType} from "./EstablishmentType";
import {EnvironmentService} from "../services/environment.service";

export interface ProfilePicture {
  id: string;
  url: string;
}

export class UserProfile {
  id: string = "";
  user!: User;
  created_at: string = "";
  updated_at: string = "";
  organisation?: Organisation;
  organisation_name: string = "";
  roles: string[] = [];
  check_number: string = "";
  user_last_login: string = "";
  profile_type: string = "";
  tenant!: Tenant;
  signup_complete: boolean = false;
  profile_picture: ProfilePicture | null = null;
  pathway: string = "";
  position: string = "";
  user_active = false;

  establishment_type: EstablishmentType | null = null;

  static create(object:any, env: EnvironmentService | null = null): UserProfile {
    const item = JsonAdapter.fill(new UserProfile(), object);
    if(object.user) {
      item.user = User.create(object.user);
    }

    if(object.registration_source && object.registration_source.code) {
      item.tenant = object.registration_source.code;
    }

    if(object.organisation) {
      item.organisation = Organisation.create(object.organisation, env);
      item.organisation_name = item.organisation.name;
    }
    if(object.establishment_type) {
      item.establishment_type = EstablishmentType.create(object.establishment_type);
    }

    item.profile_picture = object.profile_picture;
    item.course = object.course;

    return item;
  }

  static createMultiple(objects: UserProfile[]): UserProfile[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(UserProfile.create(objects[i]));
    }
    return items;
  }

  get fullName() {
    if(!this.user) {
      return '';
    }

    return this.user.name;
  }

  get fullNameWithPosition() {
    return this.fullName + (this.position ? ' (' + this.position + ')' : '');
  }

  hasRole(role: string, includeAdmin: boolean = true) {
    const roles: string[] = !Array.isArray(role) ? [role] : role;
    if(!roles.includes('admin') && includeAdmin) {
      roles.push('admin');
    }
    return this.roles.filter(i => roles.includes(i)).length || (this.user && this.user.roles.filter(i => roles.includes(i)).length);
  }

  isIndividual() {
    return this.profile_type === 'individual' && !this.establishment_type;
  }

  isStudent() {
    return this.establishment_type?.name === 'University';
  }

  isCompany() {
    return this.profile_type === 'company';
  }

  isInstitute() {
    return this.profile_type === 'university';
  }

  isOrganisation() {
    return this.isInstitute() || this.isCompany();
  }

  isAdmin() {
    return this.hasRole('admin');
  }

  get profileType() {
    if(this.isIndividual()) {
      return 'Individual';
    } else if(this.isStudent()) {
      return 'Student';
    } else if(this.isInstitute()) {
      return this.profile_type;
    } else if(this.isCompany()) {
      return 'Company';
    } else if(this.isAdmin()) {
      return 'admin';
    }

    return 'Employee';
  }

  get avatar() {
    if(this.profile_picture && this.profile_picture.id) {
      return environment.apiBaseUrl + "individual/" + this.id + "/profile_picture/" + this.profile_picture.id + "?thumbnail=true";
    }
    return "/assets/images/profiles/placeholder.svg";
  }

  hasPathway() {
    return this.pathway;
  }

  get first_name() {
    return this.user?.first_name || '';
  }

  get last_name() {
    return this.user?.last_name || '';
  }
}
