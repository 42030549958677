import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {LayoutModule} from "./shared/modules/layout/layout.module";
import {AuthGuard} from "./shared/auth/guards/auth.guard";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {JWT_OPTIONS, JwtModule} from "@auth0/angular-jwt";
import {CookieModule, CookieService} from "ngx-cookie";
import {TenantInterceptor} from "./tenant/tenant.interceptor";
import {AppRoutingModule} from "./app-routing.module";
import {TenantConfigService} from "./tenant/services/tenant-config.service";
import {ErrorInterceptorService} from "./shared/interceptors/error-interceptor.service";
import '@angular/localize/init';
import {PasswordStrengthMeterModule} from "angular-password-strength-meter";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {EnvironmentService} from "./shared/services/environment.service";

export function tenantConfigFactory(config: TenantConfigService) {
  return  () => config.load();
}

export function reCaptchaConfigFactory(env: EnvironmentService) {
  return env.tenantConfig?.recaptcha.sitekey;
}

export function jwtOptionsFactory(cookieService: CookieService) {
  return {
    tokenGetter: () => {
      return cookieService.get("access_token");
    },
    allowedDomains: [
      "api.devel.talentview.org",
      "api.talentview.org",
      "api.devel.destinationnuclearcareersportal.co.uk",
      "api.demo.plus.talentview.org",
      "api.phase2.destinationnuclearcareersportal.co.uk",
    ],
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CookieModule.withOptions(),
    LayoutModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    MatSnackBarModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CookieService]
      }
    }),
    PasswordStrengthMeterModule.forRoot()
  ],
  providers: [
    TenantConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: tenantConfigFactory,
      deps: [TenantConfigService],
      multi: true
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      deps: [EnvironmentService],
      useFactory: reCaptchaConfigFactory
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
