import {JsonAdapter} from "../helpers/JsonAdapter";
import {environment} from "../../../environments/environment";
import {EnvironmentService} from "../services/environment.service";

export class Organisation {
  id: string = "";
  name: string = "";
  slug: string = "";
  show_microsite: boolean = false;
  website: string = "";
  logo: string = "";
  microsite_config: any = {
    hide_logo: false
  };

  social_channel: any = {
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: ""
  }

  _current_user_meta!: any;
  cover_image: string = "";

  static create(object:any, env: EnvironmentService | null = null): Organisation {
    const item = JsonAdapter.fill(new Organisation(), object);

    item.logo = (env ? env.apiBaseUrl : environment.apiV1BaseUrl) + 'organisation/' + object.id + '/logo/current';

    if(object.microsite_config) {
      item.microsite_config = object.microsite_config;
    }

    if(object.social_channel) {
      item.social_channel = object.social_channel;
    }

    item._current_user_meta = object._current_user_meta;

    return item;
  }

  static createMultiple(objects: Organisation[], env: EnvironmentService | null = null): Organisation[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Organisation.create(objects[i], env));
    }
    return items;
  }

  get jobsUrl() {
    return '/organisation-hub/' + (this.slug || this.id) + '/jobs';
  }

  get url() {
    return '/organisation-hub/' + (this.slug || this.id);
  }
}
