import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GatewayComponent } from '../../layout/gateway/gateway.component';
import {HeaderAndFooterModule} from "./header-and-footer.module";
import {PromptModalComponent} from "../../components/modals/prompt-modal/prompt-modal.component";
import {BasicModalComponent} from "../../components/modals/basic-modal/basic-modal.component";
import {TenantGatewayComponent} from "../../../tenant/tenant-gateway/tenant-gateway.component";
import {AuthorizationRequiredModalComponent} from "../../components/modals/authorization-required-modal/authorization-required-modal.component";

@NgModule({
  declarations: [
    TenantGatewayComponent,
    GatewayComponent,
    BasicModalComponent,
    PromptModalComponent,
    AuthorizationRequiredModalComponent
  ],
  imports: [
    CommonModule,
    HeaderAndFooterModule
  ],
  exports: [
    CommonModule,
    HeaderAndFooterModule,
    BasicModalComponent,
    PromptModalComponent
  ]
})
export class LayoutModule { }
