import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from "../../components/header/header.component";
import {TopNavigationComponent} from "../../components/navigations/top-navigation/top-navigation.component";
import {RouterModule} from "@angular/router";
import {GraphicModule} from "../graphic/graphic.module";
import {NgbDropdownModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    HeaderComponent,
    TopNavigationComponent
  ],
  imports: [
      CommonModule,
      RouterModule,
      GraphicModule,
      NgbTooltipModule,
      NgbDropdownModule
  ],
  exports: [
    RouterModule,
    GraphicModule,
    HeaderComponent,
    TopNavigationComponent
  ]
})
export class HeaderModule { }
