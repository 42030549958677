import {JsonAdapter} from "../../shared/helpers/JsonAdapter";
import {NavigationItem} from "../../shared/models/NavigationItem";

export class TenantConfig {
  tenant: string = "";
  siteName: string = "";
  hosts: string[] = [];
  baseUrl: string = "";
  apiBaseUrl: string = "";
  apiV1BaseUrl: string = "";
  recaptcha: any = null;
  logo: string = "";
  internalLogoLink: string = "";
  notification: { description: string } = {description: ""};
  locker: {enabled: boolean} = {enabled: true};
  dashboard: {header: {enabled: boolean}} = {header: {enabled: true}};
  organisationHub: {AZMenuItems: string, organisationListType: string, organisationPageLayout: string, showJobsTab: boolean} = {AZMenuItems: "", organisationListType: "", organisationPageLayout: "", showJobsTab: true};
  signIn: {layout: string, fullscreen: boolean, title: string,logo_visible: boolean} = {layout: "cover", fullscreen: true, title: "", logo_visible: true};
  signUp: {
    enabled: boolean,
    layout: string,
    fullscreen: boolean,
    title: string,
    subtitle: string,
    completionPage: {
      enabled: boolean,
      url: string
    },
    verificationEmailReceived: {
      title: string,
      subtitle: string,
      hideResend: boolean
    },
    logo_visible: boolean
  } = {enabled: true, layout: "cover", fullscreen: true, title: "", subtitle: "", completionPage: {
      enabled: true,
      url: ''
    }, verificationEmailReceived: {title: "", subtitle: "", hideResend: false}, logo_visible: true};
  topNavigation: {config: any, items: NavigationItem[], hideNotification: boolean} = {config: {}, items: [], hideNotification: false};
  profile: { navigation: {config: any, items: NavigationItem[]}, profilePageDisabled: boolean, headerSubtitle: string, infoText: string, avatarDisabled: boolean} = {navigation: {config: {}, items: []}, profilePageDisabled: false, headerSubtitle: "", infoText: "", avatarDisabled: false};
  internalNavigation: { [k: string]: {config: any, itemGroups: {group: string, title: string, items: NavigationItem[]}[]}} = {admin: {config: {}, itemGroups: []}};

  static create(object:any): TenantConfig {
    const item = JsonAdapter.fill(new TenantConfig(), object);

    if(object.hosts) {
      item.hosts = object.hosts;
    }

    if(object.organisationHub) {
      item.organisationHub = object.organisationHub;
    }

    if(object.topNavigation) {
      item.topNavigation = object.topNavigation;
    }

    if(object.internalNavigation) {
      item.internalNavigation = object.internalNavigation;
    }

    if(object.profile) {
      item.profile = object.profile;
    }

    if(object.signIn) {
      item.signIn = object.signIn;
    }

    if(object.signUp) {
      item.signUp = object.signUp;
    }

    return item;
  }
}
